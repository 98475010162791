import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Row, Col } from "../components/page-components/grid";
import Md from "gatsby-custom-md";
import "../style/project-singlepage.less";
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
import SectionTitle from "../components/sectiontitle";
deckDeckGoHighlightElement();

const components = {
  row: Row,
  col: Col
};

export default function ProjectShow({ data }) {
  return (
    <Layout>
      <Seo
        lang="en"
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.description}
        image={data.markdownRemark.frontmatter.image.publicURL}
      />
      <div className="container">
        <article className="project-post">
          <div className="section-title">
            <SectionTitle title={data.markdownRemark.frontmatter.title} />
          </div>
          <div className="head text-primary">
            {/* <h1>{data.markdownRemark.frontmatter.title}</h1> */}
            <p className="post-date">
              {data.markdownRemark.frontmatter.byline}
            </p>
          </div>
          <div className="content row flex">
            <div className="col s12">
              <Md
                components={components}
                htmlAst={data.markdownRemark.htmlAst}
              />
            </div>
          </div>
        </article>
      </div>
    </Layout>
  );
}

export const query = graphql`
query ($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    htmlAst
    id
    frontmatter {
      title
      byline
      description
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`;
